<template>
    <v-container fill-height class="text-center">
        <v-row align-content="center" justify="center" class="opacity-5">
            <v-col cols="12" class="text-center">
                <slot>No data available</slot>
            </v-col>
            <v-col cols="6">
                <v-icon large>{{ icon || 'mdi-dots-square' }}</v-icon>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'DashboardEmpty',
    props: { icon: String }
}
</script>

<style lang="scss" scoped></style>
